






















































































import gql from 'graphql-tag'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class BlockEditorCreate extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string

  name = ''
  creating = false
  nameErrors: string[] = []

  async create() {
    this.creating = true
    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation createVueBlock($environmentId: ID, $name: String) {
            createVueBlock(environmentId: $environmentId, name: $name) {
              _id
            }
          }
        `,
        variables: {
          environmentId: this.environmentId,
          name: this.name
        }
      })
      const { _id: blockId } = data.createVueBlock
      this.$emit('created')
      return this.$router.push({
        name: 'adminVueBlockEdit',
        params: {
          environmentId: this.environmentId,
          componentId: blockId
        }
      })
    } catch (e) {
      console.error(e)
      if (e.graphQLErrors) {
        e.graphQLErrors.forEach((err: any) => {
          if (err.error === 'validationError') {
            this.nameErrors = [String(this.$t('fullview_create.script.create.unique'))]
          }
        })
      }
      return this.$store.dispatch('snackbar/showSnackbar', {
        color: 'error',
        text: this.$t('fullview_create.script.create.error')
      })
    } finally {
      this.creating = false
    }
  }
}
