import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

export default [
  {
    test: /./,
    title: 'General',
    attrs: {
      id: {
        type: 'string',
        label: $t('fullview_attr.label'),
        description: $t('fullview_attr.description')
      },
      class: {
        type: 'string',
        label: $t('fullview_attr.class.label'),
        description: $t('fullview_attr.class.description')
      }
    },
    events: ['click']
  },
  {
    test: /^v-btn$/,
    title: $t('fullview_attr.boton.title'),
    attrs: {
      color: {
        type: 'color',
        label: $t('fullview_attr.boton.color.label'),
        description: $t('fullview_attr.boton.color.description')
      },
      to: {
        type: 'string',
        label: $t('fullview_attr.boton.to.label'),
        description: $t('fullview_attr.boton.to.description')
      },
      replace: {
        type: 'checkbox',
        label: $t('fullview_attr.boton.replace.label'),
        description: $t('fullview_attr.boton.replace.description')
      },
      target: {
        type: 'select',
        label: $t('fullview_attr.boton.target.label'),
        description: $t('fullview_attr.boton.target.description'),
        fieldOptions: {
          options: [
            { value: '_blank', label: $t('fullview_attr.boton.target.blank') },
            { value: '_self', label: $t('fullview_attr.boton.target.self') }
          ]
        }
      },
      loading: {
        type: 'checkbox',
        label: $t('fullview_attr.boton.loading.label'),
        description: $t('fullview_attr.boton.loading.description')
      },
      disabled: {
        type: 'checkbox',
        label: $t('fullview_attr.boton.disabled.label'),
        description: $t('fullview_attr.boton.disabled.description')
      },
      dark: {
        type: 'checkbox',
        label: $t('fullview_attr.boton.dark.label'),
        description: $t('fullview_attr.boton.dark.description')
      },
      block: {
        type: 'checkbox',
        label: $t('fullview_attr.boton.block.label'),
        description: $t('fullview_attr.boton.block.description')
      },
      depressed: {
        type: 'checkbox',
        label: $t('fullview_attr.boton.depressed.label'),
        description: $t('fullview_attr.boton.depressed.description')
      },
      text: {
        type: 'checkbox',
        label: $t('fullview_attr.boton.text.label'),
        description: $t('fullview_attr.boton.text.description')
      },
      outlined: {
        type: 'checkbox',
        label: $t('fullview_attr.boton.outlined.label'),
        description: $t('fullview_attr.boton.outlined.description')
      },
      rounded: {
        type: 'checkbox',
        label: $t('fullview_attr.boton.rounded.label'),
        description: $t('fullview_attr.boton.rounded.description')
      },
      tile: {
        type: 'checkbox',
        label: $t('fullview_attr.boton.tile.label'),
        description: $t('fullview_attr.boton.tile.description')
      },
      fab: {
        type: 'checkbox',
        label: $t('fullview_attr.boton.fab.label'),
        description: $t('fullview_attr.boton.fab.description')
      },
      icon: {
        type: 'checkbox',
        label: $t('fullview_attr.boton.icon.label'),
        description: $t('fullview_attr.boton.icon.description')
      },
      elevation: {
        type: 'number',
        label: $t('fullview_attr.boton.elevation.label'),
        description: $t('fullview_attr.boton.elevation.description')
      },
      'x-small': {
        type: 'checkbox',
        label: $t('fullview_attr.boton.x-small.label'),
        description: $t('fullview_attr.boton.x-small.description')
      },
      small: {
        type: 'checkbox',
        label: $t('fullview_attr.boton.small.label'),
        description: $t('fullview_attr.boton.small.description')
      },
      large: {
        type: 'checkbox',
        label: $t('fullview_attr.boton.large.label'),
        description: $t('fullview_attr.boton.large.description')
      },
      'x-large': {
        type: 'checkbox',
        label: $t('fullview_attr.boton.x-large.label'),
        description: $t('fullview_attr.boton.x-large.description')
      }
    }
  },
  {
    test: /^v-row$/,
    title: $t('fullview_attr.fila.title'),
    attrs: {
      align: {
        type: 'select',
        label: $t('fullview_attr.fila.align.label'),
        description: $t('fullview_attr.fila.align.description'),
        fieldOptions: {
          options: [
            { value: 'start', label: $t('fullview_attr.fila.align.start') },
            { value: 'center', label: $t('fullview_attr.fila.align.center') },
            { value: 'end', label: $t('fullview_attr.fila.align.end') },
            { value: 'baseline', label: $t('fullview_attr.fila.align.baseline') }
          ]
        }
      },
      justify: {
        type: 'select',
        label: $t('fullview_attr.fila.justify.label'),
        description: $t('fullview_attr.fila.justify.description'),
        fieldOptions: {
          options: [
            { value: 'start', label: $t('fullview_attr.fila.justify.start') },
            { value: 'center', label: $t('fullview_attr.fila.justify.center') },
            { value: 'end', label: $t('fullview_attr.fila.justify.end') },
            { value: 'space-around', label: $t('fullview_attr.fila.justify.space-around') },
            { value: 'space-between', label: $t('fullview_attr.fila.justify.space-between') }
          ]
        }
      },
      'no-gutters': {
        type: 'checkbox',
        label: $t('fullview_attr.fila.no-gutters.label'),
        description: $t('fullview_attr.fila.no-gutters.description')
      }
    },
    actions: [
      {
        name: 'addColumn',
        icon: 'add',
        title: $t('fullview_attr.fila.actions.title'),
        action: 'insertChild',
        options: {
          nodeName: 'v-col',
          tagName: 'v-col',
          attrs: [],
          childNodes: []
        }
      }
    ]
  },
  {
    test: /^v-col$/,
    title: $t('fullview_attr.columna.title'),
    attrs: {
      cols: {
        type: 'number',
        label: $t('fullview_attr.columna.cols.label'),
        description: $t('fullview_attr.columna.cols.description')
      },
      xs: {
        type: 'number',
        label: $t('fullview_attr.columna.xs.label'),
        description: $t('fullview_attr.columna.xs.description')
      },
      sm: {
        type: 'number',
        label: $t('fullview_attr.columna.sm.label'),
        description: $t('fullview_attr.columna.sm.description')
      },
      md: {
        type: 'number',
        label: $t('fullview_attr.columna.md.label'),
        description: $t('fullview_attr.columna.md.description')
      },
      lg: {
        type: 'number',
        label: $t('fullview_attr.columna.lg.label'),
        description: $t('fullview_attr.columna.lg.description')
      },
      xl: {
        type: 'number',
        label: $t('fullview_attr.columna.xl.label'),
        description: $t('fullview_attr.columna.xl.description')
      },
      offset: {
        type: 'number',
        label: $t('fullview_attr.columna.offset.label'),
        description: $t('fullview_attr.columna.offset.description')
      },
      'offset-xs': {
        type: 'number',
        label: $t('fullview_attr.columna.offset-xs.label'),
        description: $t('fullview_attr.columna.offset-xs.description')
      },
      'offset-sm': {
        type: 'number',
        label: $t('fullview_attr.columna.offset-sm.label'),
        description: $t('fullview_attr.columna.offset-sm.description')
      },
      'offset-md': {
        type: 'number',
        label: $t('fullview_attr.columna.offset-md.label'),
        description: $t('fullview_attr.columna.offset-md.description')
      },
      'offset-lg': {
        type: 'number',
        label: $t('fullview_attr.columna.offset-lg.label'),
        description: $t('fullview_attr.columna.offset-lg.description')
      },
      'offset-xl': {
        type: 'number',
        label: $t('fullview_attr.columna.offset-xl.label'),
        description: $t('fullview_attr.columna.offset-xl.description')
      }
    }
  },
  {
    test: /^v-tabs$/,
    title: $t('fullview_attr.pestanas.title'),
    attrs: {
      'align-with-title': {
        type: 'checkbox',
        label: $t('fullview_attr.pestanas.align-with-title.label'),
        description: $t('fullview_attr.pestanas.align-with-title.description')
      },
      'background-color': {
        type: 'color',
        label: $t('fullview_attr.pestanas.background-color.label'),
        description: $t('fullview_attr.pestanas.background-color.description')
      },
      'center-active': {
        type: 'checkbox',
        label: $t('fullview_attr.pestanas.center-active.label'),
        description: $t('fullview_attr.pestanas.center-active.description')
      },
      dark: {
        type: 'checkbox',
        label: $t('fullview_attr.pestanas.dark.label'),
        description: $t('fullview_attr.pestanas.dark.description')
      },
      'fixed-tabs': {
        type: 'checkbox',
        label: $t('fullview_attr.pestanas.fixed-tabs.label'),
        description: $t('fullview_attr.pestanas.fixed-tabs.description')
      },
      grow: {
        type: 'checkbox',
        label: $t('fullview_attr.pestanas.grow.label'),
        description: $t('fullview_attr.pestanas.grow.description')
      },
      'icons-and-text': {
        type: 'checkbox',
        label: $t('fullview_attr.pestanas.icons-and-text.label'),
        description: $t('fullview_attr.pestanas.icons-and-text.description')
      },
      'icons-right': {
        type: 'checkbox',
        label: $t('fullview_attr.pestanas.icons-right.label'),
        description: $t('fullview_attr.pestanas.icons-right.description')
      },
      right: {
        type: 'checkbox',
        label: $t('fullview_attr.pestanas.right.label'),
        description: $t('fullview_attr.pestanas.right.description')
      },
      'show-arrows': {
        type: 'checkbox',
        label: $t('fullview_attr.pestanas.show-arrows.label'),
        description: $t('fullview_attr.pestanas.show-arrows.description')
      },
      'slider-color': {
        type: 'color',
        label: $t('fullview_attr.pestanas.slider-color.label'),
        description: $t('fullview_attr.pestanas.slider-color.description')
      },
      'slider-size': {
        type: 'number',
        label: $t('fullview_attr.pestanas.slider-size.label'),
        description: $t('fullview_attr.pestanas.slider-size.description')
      },
      vertical: {
        type: 'checkbox',
        label: $t('fullview_attr.pestanas.vertical.label'),
        description: $t('fullview_attr.pestanas.vertical.description')
      }
    },
    actions: [
      {
        name: 'addTab',
        icon: 'add',
        title: $t('fullview_attr.pestanas.actions.title'),
        action: 'insertChild',
        options: {
          nodeName: 'v-tab',
          tagName: 'v-tab',
          attrs: [],
          childNodes: []
        }
      }
    ]
  },
  {
    test: /^v-(text-field|textarea|select|autocomplete)$/,
    title: $t('fullview_attr.camp.title'),
    attrs: {
      'v-model': {
        type: 'string',
        label: $t('fullview_attr.camp.v-model.label'),
        description: $t('fullview_attr.camp.v-model.description'),
        placeholder: $t('fullview_attr.camp.v-model.placeholder'),
        noBind: true
      },
      label: {
        type: 'string',
        label: $t('fullview_attr.camp.label.label'),
        description: $t('fullview_attr.camp.label.description')
      },
      'background-color': {
        type: 'color',
        label: $t('fullview_attr.camp.background-color.label'),
        description: $t('fullview_attr.camp.background-color.description')
      },
      color: {
        type: 'color',
        label: $t('fullview_attr.camp.color.label'),
        description: $t('fullview_attr.camp.color.description')
      },
      'clear-icon': {
        type: 'checkbox',
        label: $t('fullview_attr.camp.clear-icon.label'),
        description: $t('fullview_attr.camp.clear-icon.description')
      },
      counter: {
        type: 'number',
        label: $t('fullview_attr.camp.counter.label'),
        description: $t('fullview_attr.camp.counter.description')
      },
      dark: {
        type: 'checkbox',
        label: $t('fullview_attr.camp.dark.label'),
        description: $t('fullview_attr.camp.dark.description')
      },
      filled: {
        type: 'checkbox',
        label: $t('fullview_attr.camp.filled.label'),
        description: $t('fullview_attr.camp.filled.description')
      },
      outlined: {
        type: 'checkbox',
        label: $t('fullview_attr.camp.outlined.label'),
        description: $t('fullview_attr.camp.outlined.description')
      },
      flat: {
        type: 'checkbox',
        label: $t('fullview_attr.camp.flat.label'),
        description: $t('fullview_attr.camp.flat.description')
      },
      solo: {
        type: 'checkbox',
        label: $t('fullview_attr.camp.solo.label'),
        description: $t('fullview_attr.camp.solo.description')
      },
      'full-width': {
        type: 'checkbox',
        label: $t('fullview_attr.camp.full-width.label'),
        description: $t('fullview_attr.camp.full-width.description')
      },
      height: {
        type: 'number',
        label: $t('fullview_attr.camp.height.label'),
        description: $t('fullview_attr.camp.height.description')
      },
      'hide-details': {
        type: 'checkbox',
        label: $t('fullview_attr.camp.hide-details.label'),
        description: $t('fullview_attr.camp.hide-details.description')
      },
      hint: {
        type: 'string',
        label: $t('fullview_attr.camp.hint.label'),
        description: $t('fullview_attr.camp.hint.description')
      },
      'persistent-hint': {
        type: 'checkbox',
        label: $t('fullview_attr.camp.persistent-hint.label'),
        description: $t('fullview_attr.camp.persistent-hint.description')
      },
      'append-icon': {
        type: 'string',
        label: $t('fullview_attr.camp.append-icon.label'),
        description: $t('fullview_attr.camp.append-icon.description')
      },
      'append-outer-icon': {
        type: 'string',
        label: $t('fullview_attr.camp.append-outer-icon.label'),
        description: $t('fullview_attr.camp.append-outer-icon.description')
      },
      'prepend-icon': {
        type: 'string',
        label: $t('fullview_attr.camp.prepend-icon.label'),
        description: $t('fullview_attr.camp.prepend-icon.description')
      },
      'prepend-outer-icon': {
        type: 'string',
        label: $t('fullview_attr.camp.prepend-outer-icon.label'),
        description: $t('fullview_attr.camp.prepend-outer-icon.description')
      },
      type: {
        type: 'string',
        label: $t('fullview_attr.camp.type.label'),
        description: $t('fullview_attr.camp.type.description')
      },
      readonly: {
        type: 'checkbox',
        label: $t('fullview_attr.camp.readonly.label'),
        description: $t('fullview_attr.camp.readonly.description')
      },
      'single-line': {
        type: 'checkbox',
        label: $t('fullview_attr.camp.single-line.label'),
        description: $t('fullview_attr.camp.single-line.description')
      },
      autofocus: {
        type: 'checkbox',
        label: $t('fullview_attr.camp.autofocus.label'),
        description: $t('fullview_attr.camp.autofocus.description')
      }
    },
    events: ['change', 'input', 'focus', 'blur']
  }
]
