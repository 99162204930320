import screenshot from './screenshot.png'
import { PrefabDefinition } from '../PrefabDefinition'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}
export default {
  id: 'rating',
  version: 1,
  name: $t('fullview_rating.name'),
  description: $t('fullview_rating.description'),
  image: screenshot,
  docsUrl: 'https://vuetifyjs.com/en/components/ratings/',
  library: 'Vuetify',
  tag: 'v-rating',
  props: {
    color: {
      type: 'color',
      label: $t('fullview_rating.color'),
      optional: true
    },
    'background-color': {
      type: 'color',
      label: $t('fullview_rating.back_color'),
      optional: true
    }
  }
} as PrefabDefinition
