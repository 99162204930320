







































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { PaginatedQueryResult, VueBlock } from '@/models'
import gql from 'graphql-tag'
import FuzzySearch from 'fuzzy-search'

@Component({
  apollo: {
    vueBlocks: {
      query() {
        return gql`
          query getVueBlocks($environmentId: ID) {
            vueBlocks(environmentId: $environmentId) {
              items {
                _id
                name
                title
              }
            }
          }
        `
      },
      variables() {
        return {
          environmentId: this.environmentId,
        }
      },
      fetchPolicy: 'network-only',
    },
  },
})
export default class VueBlockEditorHome extends Vue {
  @Prop({ type: String, required: true }) environmentId!: String
  vueBlocks: Partial<PaginatedQueryResult<VueBlock>> = {}
  searchQuery = ''

  get blockResults() {
    if (!this.vueBlocks.items) return []
    const displayResult = this.vueBlocks.items.map((block) => ({
      ...block,
      icon: 'api',
    }))
    const searcher = new FuzzySearch(displayResult || [], [
      'name',
      'title',
      '_id',
    ])
    return searcher.search(this.searchQuery ?? '')
  }
}
