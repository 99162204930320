import screenshot from './screenshot.png'
import { PrefabDefinition } from '../PrefabDefinition'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}
export default {
  id: 'calendar',
  version: 1,
  name: $t('fullview_calendar.name'),
  description: $t('fullview_calendar.description'),
  image: screenshot,
  docsUrl: 'https://vuetifyjs.com/en/components/calendars/',
  library: 'Vuetify',
  tag: 'v-calendar'
} as PrefabDefinition
