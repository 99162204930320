import screenshot from './screenshot.png'
import { PrefabDefinition } from '../PrefabDefinition'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}
export default {
  id: 'button',
  version: 1,
  name: $t('fullview_button.name'),
  description: $t('fullview_button.description'),
  image: screenshot,
  docsUrl: 'https://vuetifyjs.com/en/components/buttons/',
  library: 'Vuetify',
  tag: 'v-btn',
  textContent: $t('fullview_button.content'),
  props: {
    color: {
      type: 'color',
      editableDefaultValue: 'primary',
      label: $t('fullview_button.color'),
      optional: true
    },
    dark: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: $t('fullview_button.dark'),
      optional: true
    },
    depressed: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: $t('fullview_button.depressed'),
      optional: true
    },
    outlined: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: $t('fullview_button.outlined'),
      optional: true
    },
    text: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: $t('fullview_button.text'),
      optional: true
    },
    block: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: $t('fullview_button.block'),
      optional: true
    },
    rounded: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: $t('fullview_button.rounded'),
      optional: true
    },
    tile: {
      type: 'checkbox',
      editableDefaultValue: false,
      label:  $t('fullview_button.tile'),
      optional: true
    },
    'x-small': {
      type: 'checkbox',
      editableDefaultValue: false,
      label: $t('fullview_button.x-small'),
      optional: true
    },
    small: {
      type: 'checkbox',
      editableDefaultValue: false,
      label: $t('fullview_button.small'),
      optional: true
    },
    large: {
      type: 'checkbox',
      editableDefaultValue: false,
      label:$t('fullview_button.large'),
      optional: true
    },
    'x-large': {
      type: 'checkbox',
      editableDefaultValue: false,
      label: $t('fullview_button.x-large'),
      optional: true
    }
  }
} as PrefabDefinition
