





















































































































import { VueBlock } from '@/models'
import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import { confirmDelete, prompt } from '@/components/dialogs'
import Fields from '@/components/form/Fields.vue'
import gql from 'graphql-tag'

@Component({
  components: {
    Fields
  }
})
export default class VueBlockEditorEditOptions extends Vue {
  @PropSync('vueBlock', { type: Object, required: true }) block!: VueBlock
  @PropSync('isSaving', { type: Boolean, required: true }) saving!: boolean
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: String, required: true }) componentId!: string

  async save() {
    this.$emit('save')
  }

  async rename() {
    const newName = await prompt(
      String(this.$t('fullview_editOpt.schema.inject.label')),
      {
        defaultValue: this.block.name
      }
    )
    if (newName !== false) {
      this.block.name = newName as string
      await this.save()
    }
  }

  async duplicate() {
    const cloneName = await prompt(
      String(this.$t('fullview_editOpt.script.duplicate.prompt')),
      {
        defaultValue:
          this.block.name + this.$t('fullview_editOpt.script.duplicate.copy')
      }
    )
    if (cloneName !== false) {
      try {
        this.saving = true
        const { data } = await this.$apollo.mutate({
          mutation: gql`
            mutation duplicateVueBlock($vueBlockId: ID, $newName: String) {
              result: duplicateVueBlock(
                vueBlockId: $vueBlockId
                newName: $newName
              ) {
                _id
              }
            }
          `,
          variables: {
            vueBlockId: this.componentId,
            newName: cloneName
          }
        })
        const { _id: newId } = data.result
        this.$emit('updated')
        return this.$router.push({
          name: 'adminVueBlockEdit',
          params: {
            environmentId: this.environmentId,
            componentId: newId
          }
        })
      } catch (e) {
        console.error(e)
        this.$emit('error', e)
      } finally {
        this.saving = false
      }
    }
  }

  async deleteItem() {
    if (
      !(await confirmDelete(
        String(this.$t('fullview_editOpt.script.deleteItem.confirm'))
      ))
    ) {
      return
    }
    if (!this.block || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($vueBlockId: ID) {
            deleteVueBlock(vueBlockId: $vueBlockId)
          }
        `,
        // Parameters
        variables: {
          vueBlockId: this.componentId
        }
      })
      this.$emit('updated')
      this.$emit('delete', result.data.deleteBlock)
      return this.$router.push({
        name: 'adminVueBlockIndex',
        params: {
          environmentId: this.environmentId
        }
      })
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }
}
