export default function getNodeAtLocation(node: any, offset: number): any {
  const loc = node.sourceCodeLocation
  if (node.childNodes?.length > 0) {
    for (let i = 0; i < node.childNodes.length; i++) {
      const n = getNodeAtLocation(node.childNodes[i], offset)
      if (n) {
        return n
      }
    }
  }
  if (
    node.tagName &&
    loc &&
    loc.startOffset <= offset &&
    loc.endOffset >= offset
  ) {
    return node
  }
}
